import React, { useEffect, useState, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import socket from '../../../socket/socket'
import { Modal, Button, Alert } from 'react-bootstrap'
import axios from 'axios'
import { GiClick } from 'react-icons/gi'
import './Chat.css'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import moment from 'moment'

import { FaRegUserCircle } from 'react-icons/fa'
import { BsList } from 'react-icons/bs'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter
} from 'mdb-react-ui-kit'
import Dropdown from 'react-bootstrap/Dropdown'

const Chat = props => {
  const [topRightModal, setTopRightModal] = useState(false)

  const [messages, setMessages] = useState([])
  const [inputValue, setInputValue] = useState('')
  const [isFileSelected, setIsFileSelected] = useState(false)
  const [allMsg, setAllMsg] = useState([])
  const [selectedFile, setSelectedFile] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalShow, setModalShow] = React.useState(false)
  const [showChatList, setShowChatList] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [isloading, setIsLoading] = useState(false)
  const history = useHistory()

  const [List, setList] = useState([])
  const [ConversationId, setConversationId] = useState([])
  const [userId, setUserId] = useState([])
  const [ticket, setTicket] = useState({})
  const [RemarkMsg, setRemarkMsg] = useState({})
  const [isNew, isnewSet] = useState(true)

  const Token = localStorage.getItem('token')

  const handleSearch = e => {
    setSearchQuery(e.target.value)
  }

  // Filter data based on search query
  const filteredData = List.filter(item => {
    const itemData = item?.ticket_id.toLowerCase() // Change 'name' to the relevant property in your API data
    return itemData && itemData?.includes(searchQuery.toLowerCase())
  })

  const TicketList = () => {
    // Conversation()
    // setIsLoading(true)
    var myHeaders = new Headers()
    myHeaders.append('Authorization', Token)

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    }
    fetch(
      'https://api.probattle.in/chat/ticket-list/admin?page=1&limit=1000',
      requestOptions
    )
      .then(response => response.json())
      .then(result => {
        // setIsLoading(false)
        setList(result.results)
        if (isNew) {
          Conversation(result.results[0])
        }
        isnewSet(false)
      })
      .catch(error => {
        // setIsLoading(false)
        console.log(error)
      })
  }

  const Conversation = ticketobj => {
    setIsLoading(true)
    setUserId(ticketobj.user_id)
    setTicket(ticketobj)
    setConversationId(ticketobj._id)
    var myHeaders = new Headers()
    myHeaders.append('Authorization', Token)

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    }

    // const conversationId =  localStorage.getItem("id")|| ticketobj._id ; // Use ticketobj._id if available, otherwise fallback to List[0]._id

    fetch(
      `https://api.probattle.in/chat/conversation/${ticketobj._id}?page=1&limit=50`,
      requestOptions
    )
      .then(response => response.json())
      .then(result => {
        setIsLoading(false)
        if (result) {
          setShowChatList(!showChatList)
        }
        setAllMsg(result.results)
      })
      .catch(error => {
        setIsLoading(false)
        console.log(error)
      })
  }

  // localStorage.setItem("id", List[0]?._id)//

  const openModal = () => {
    setModalShow(true)
  }

  const access_token = localStorage.getItem('token')
  const [user, setUser] = useState({})
  // useEffect(()=>{
  //   socket.emit("joinUser", "dekho kitni bar call hua")

  // },[])
  const getUser = () => {
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    axios
      .get('https://api.probattle.in/me', { headers })
      .then(res => {
        socket.emit('joinUser', res.data)
        console.log(socket)
        setUser(res.data)
        // toast("Login Success")
      })
      // console.log("first")
      .catch(e => {
        toast('pls login')
        if (e.response.status == 401) {
          localStorage.removeItem('token')
          // history.pushState("/login")
        }
      })
  }

  useEffect(() => {
    getUser()
  }, [])

  const generate_url = picture => {
    const formData = new FormData()
    formData.append('media', picture)

    fetch('https://api.24x7mail.com/url', {
      method: 'POST',
      body: formData
    })
      .then(response => response.json())
      .then(json => {
        setSelectedFile(json.data[0])
      })
  }

  const closeModal = () => {
    setModalShow(false)
    setSelectedFile(null)
    setIsFileSelected(false)
  }

  const handleInputChange = e => {
    setInputValue(e.target.value)
  }

  const selectFile = () => {
    document.getElementById('myFileInput').click()
  }

  const handleFileSelect = event => {
    const file = event.target.files[0]
    generate_url(file)
    setIsFileSelected(true)
    openModal()
  }

  // socket.emit("joinUser", `${socket.id} is joined`);
  const ImageUrl = 'https://api.24x7mail.com/uploads/'

  const handleSendMessage = () => {
    inputRef.current.value = ''
    if (inputValue !== '' || isFileSelected) {
      const newMessage = {
        text: inputValue,
        recipients: userId,
        media: selectedFile && ImageUrl + selectedFile,
        chat_id: ConversationId,
        agent_id: user._id
      }
      socket.emit('create-msg', newMessage)
      setMessages([...messages, newMessage])
      setAllMsg([...allMsg, newMessage])
      setInputValue('')
      setSelectedFile(null)
      setIsFileSelected(false)
      closeModal()
    }
  }
  console.log(user?.chat_id)
  console.log(ticket.ticket_id)

  useEffect(() => {
    socket.on('recive-msg', msgs => {
      if (ticket.ticket_id === msgs.chat_id) {
        setAllMsg([...allMsg, msgs])
      }
    })

    socket.on('conversation', data => {
      setAllMsg(data.results)
    })
    socket.on('ended', data => {
      console.log(data, 'ended')
      Conversation(data)
    })

    socket.on('disconnect', data => {
      socket.emit('joinUser', user)
    })
  }, [messages, socket])

  const toggleChatList = () => {
    TicketList()
    setShowChatList(!showChatList)
  }

  const toggleShow = () => {
    setTopRightModal(!topRightModal)
    Conversation(ticket)
  }

  const EndChat = () => {
    // window.location.reload();
    TicketList()
    toggleShow()
    socket.emit('endChat', { ticket_id: ticket.ticket_id, remark: RemarkMsg })
  }
  function imageViewer () {
    let imgs = document.getElementsByClassName('img'),
      out = document.getElementsByClassName('img-out')[0]

    for (let i = 0; i < imgs.length; i++) {
      if (!imgs[i].classList.contains('el')) {
        imgs[i].classList.add('el')
        imgs[i].addEventListener('click', lightImage)
        function lightImage () {
          let container = document.getElementsByClassName('img-panel')[i]
          container.classList.toggle('img-panel__selct')
        }

        imgs[i].addEventListener('click', openImage)
        function openImage () {
          let imgElement = document.createElement('img'),
            imgWrapper = document.createElement('div'),
            imgClose = document.createElement('div'),
            container = document.getElementsByClassName('img-panel')[i]
          container.classList.add('img-panel__selct')
          imgElement.setAttribute('class', 'image__selected')
          imgElement.src = imgs[i].src
          imgWrapper.setAttribute('class', 'img-wrapper')
          imgClose.setAttribute('class', 'img-close')
          imgWrapper.appendChild(imgElement)
          imgWrapper.appendChild(imgClose)

          setTimeout(function () {
            imgWrapper.classList.add('img-wrapper__initial')
            imgElement.classList.add('img-selected-initial')
          }, 50)

          out.appendChild(imgWrapper)
          imgClose.addEventListener('click', function () {
            container.classList.remove('img-panel__selct')
            out.removeChild(imgWrapper)
          })
        }
      }
    }
  }

  useEffect(() => {
    TicketList()
    // Conversation()
  }, [])

  function getStatusColor (status) {
    switch (status) {
      case 0:
        return 'New'
      case 1:
        return 'Progress'
      case 3:
        return 'Closed'
      default:
        return ''
    }
  }

  function getStatusColor1 (status) {
    switch (status) {
      case 0:
        return '#080a7f8a'
      case 1:
        return '#087f0cb8'
      case 3:
        return '#fb004f8a'
      default:
        return ''
    }
  }

  // Enter Button
  const inputRef = useRef(null)

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleSendMessage()
    }
  }

  // Support Type
  const openPathInNewTab = path => {
    window.open(path, '_blank')
  }

  const UserType = () => {
    if (ticket.supportType === 'Profile') {
      const path = `./view_user/${ticket.user_id}`
      openPathInNewTab(path)
    } else if (ticket.supportType === 'Game') {
      if (ticket.issueid) {
        const path = `/view/${ticket.issueid}`
        openPathInNewTab(path)
      } else {
        const path = `./view_user/${ticket.user_id}`
        openPathInNewTab(path)
      }
    } else if (ticket.supportType === 'Deposit') {
      const path = `/transaction/transaction_history/${ticket.user_id}`
      openPathInNewTab(path)
    } else if (ticket.supportType === 'Referral') {
      const path = `./view_user/${ticket.user_id}`
      openPathInNewTab(path)
    } else if (
      ticket.supportType === 'Penalty' ||
      ticket.supportType === 'Bonus'
    ) {
      const path = `/transaction/penaltybonus`
      openPathInNewTab(path)
    } else if (ticket.supportType === 'Withdraw') {
      const path = `/transaction/withdraw/${ticket.user_id}`
      openPathInNewTab(path)
    } else if (ticket.supportType === 'Others') {
      const path = `./view_user/${ticket.user_id}`
      openPathInNewTab(path)
    }
  }

  const containerRef = useRef(null)

  // Scroll to the bottom on initial render and whenever new messages are added
  useEffect(() => {
    scrollToBottom()
  }, [allMsg]) // Replace `messages` with your messages data source

  const scrollToBottom = () => {
    containerRef.current.scrollTop = containerRef.current.scrollHeight
  }

  return isloading ? (
    <>
      <div className='text-center' style={{ background: 'black !important' }}>
        <div className='loaderReturn'>
          <img
            src={'/loader1.gif'}
            // style={{ width: "50%", height:"50%" }}
          />
        </div>
        {/* Add any loading spinner or animation here */}
                  
      </div>
    </>
  ) : (
    <>
      {/*<div className='chat-content'>
        <h4>Welcome to RK Ludo Support</h4>
        <span>1.Experiencing any technical issues? Please provide us with the details, and we'll do our best to assist you.</span><br/>
        <span>2.If you're encountering any errors or difficulties using our website, I'm here to help troubleshoot.</span><br/>
        <span>3.Let me know if you need assistance with setting up or configuring any features on our website.</span><br/>
      </div>*/}
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12 mx-lg-auto'>
            <div
              className={`chat-container ${
                showChatList ? 'chat-list-open' : ''
              }`}
            >
              <div className='chat-header'>
                <span
                  className='chat_list'
                  title='chat list'
                  onClick={toggleChatList}
                  style={{ fontSize: '20px' }}
                >
                  <BsList />
                </span>
                {/* <span className="chat_header">Chat</span> */}
                <span className='chat_header'>
                  Support Id : {ticket.ticket_id}
                </span>
                <span className='chat_header' onClick={UserType}>
                  <FaRegUserCircle
                    style={{ fontSize: '24px', cursor: 'pointer' }}
                  />
                </span>
              </div>
              {showChatList && (
                <div className='chat_list_cont'>
                  <input
                    type='search'
                    placeholder="What're you searching for?"
                    aria-describedby='button-addon4'
                    class='form-control bg-none border-0 rounded-pill mr-1 SearchBox'
                    value={searchQuery}
                    onChange={handleSearch}
                  />
                  {filteredData?.map(text => {
                    return (
                      <>
                        {text.status !== undefined && (
                          <div
                            className='chat_single_div'
                            onClick={() => Conversation(text)}
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              cursor: 'pointer',
                              alignItems: 'center'
                            }}
                          >
                            <div className='col-9'>
                              <div> {text.ticket_id}</div>
                              <div style={{ fontSize: '12px' }}>
                                {text.supportType}
                              </div>
                              <div
                                style={{
                                  fontSize: '12px',
                                  fontStyle: 'italic',
                                  color: getStatusColor1(text.status)
                                }}
                              >
                                {getStatusColor(text.status)}
                              </div>
                            </div>

                            <div className='col-2'>
                              {text.unseen === 0 ? (
                                ''
                              ) : (
                                <>
                                  <div className='unseen_msg'>
                                    {' '}
                                    <span> {text.unseen}</span>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    )
                  })}
                </div>
              )}

              <div
                ref={containerRef}
                className='chat-messages message-container'
              >
                {allMsg &&
                  allMsg?.map((message, index) => (
                    <div
                      key={index}
                      className={`message ${
                        message?.recipients === userId
                          ? 'user-message'
                          : 'admin-message'
                      }`}
                      style={{
                        flexDirection:
                          message?.recipients === userId ? 'row-reverse' : 'row'
                      }}
                    >
                      <div>
                        {message?.media?.length > 0 && (
                          //  <a href={message.media} target="_blank">
                          <Zoom>
                            <img
                              alt=''
                              src={message.media}
                              className='img-responsive img '
                            />
                          </Zoom>

                          // <img
                          //   src={message.media}
                          //   className="img-fluid"
                          //   style={{ height: "200px", width: "150px" }}
                          // />
                          // </a>
                        )}
                      </div>

                      <div
                        className='msg_bg'
                        style={{
                          display: message?.text?.length > 0 ? 'inline' : ''
                        }}
                      >
                        {message?.text}

                        <div>
                          {moment(message?.startTime)
                            .utcOffset('+05:30')
                            .format('llll')}
                        </div>
                        {/* <div>{new Date(message?.startTime).toLocaleString()}</div> */}
                        <div></div>
                      </div>
                    </div>
                  ))}
              </div>
              <div className='end_chat_btn'>
                <button
                  className={ticket.status === 3 ? 'd-none' : ''}
                  onClick={toggleShow}
                >
                  End Chat
                </button>
              </div>
              <div className='chat-input'>
                <input
                  type='file'
                  id='myFileInput'
                  onChange={handleFileSelect}
                  style={{ display: 'none' }}
                />

                {ticket.status === 3 ? (
                  <>
                    <div
                      className='text-center'
                      style={{ width: '100%', background: '#fff' }}
                    >
                      <div className='text-danger '>
                        {' '}
                        Chat was ended. <br />{' '}
                        <div className='text-success'>
                          Remark: {ticket.remark}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      style={{ paddingRight: '3px', cursor: 'pointer' }}
                      onClick={selectFile}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth='1.5'
                        stroke='currentColor'
                        style={{
                          height: '35px',
                          width: '35px',
                          color: isFileSelected ? 'green' : 'black'
                        }}
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M6.827 6.175A2.31 2.31 0 015.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 00-1.134-.175 2.31 2.31 0 01-1.64-1.055l-.822-1.316a2.192 2.192 0 00-1.736-1.039 48.774 48.774 0 00-5.232 0 2.192 2.192 0 00-1.736 1.039l-.821 1.316z'
                        />
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M16.5 12.75a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM18.75 10.5h.008v.008h-.008V10.5z'
                        />
                      </svg>
                    </div>
                    <input
                      type='text'
                      value={inputValue}
                      onChange={handleInputChange}
                      onKeyPress={handleKeyPress}
                      ref={inputRef}
                      placeholder={
                        ticket.status === 0
                          ? 'Send message to start support'
                          : 'Type your message...'
                      }
                      // className={ticket.status == 3 ? '':''}
                    />

                    <button onClick={handleSendMessage}>Send</button>
                  </>
                )}
              </div>
            </div>
            <Modal
              show={modalShow}
              onHide={() => setModalShow(false)}
              aria-labelledby='contained-modal-title-vcenter'
              centered
            >
              <Modal.Body className='p-1 m-0'>
                {selectedFile && (
                  <img
                    src={ImageUrl + selectedFile}
                    className='img-fluid img'
                    style={{ height: '350px', width: '100%' }}
                    alt='Selected file preview'
                  />
                )}
                <div>
                  {moment(allMsg?.startTime).utcOffset('+05:30').format('llll')}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <input
                  type='text'
                  value={inputValue}
                  onChange={handleInputChange}
                  placeholder='Type your message...'
                  className='chat_input_modal'
                />
                <button className='modal_button' onClick={handleSendMessage}>
                  Send
                </button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
      <MDBModal
        animationDirection='right'
        show={topRightModal}
        tabIndex='-1'
        setShow={setTopRightModal}
      >
        <MDBModalDialog position='top-right' side>
          <MDBModalContent>
            <MDBModalHeader className='bg-info text-white'>
              <MDBModalTitle>End Chat</MDBModalTitle>
              <MDBBtn
                color='none'
                className='btn-close btn-close-white'
                onClick={toggleShow}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <div className='row'>
                <div className='col-3 text-center'>Remark</div>

                <div className='col-9'>
                  <textarea
                    className='form-control '
                    placeholder='Thanks For Connect with us'
                    onChange={e => setRemarkMsg(e.target.value)}
                  ></textarea>
                </div>
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color='info' onClick={EndChat}>
                Submit
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
      />
      {/* Same as */}
      <ToastContainer />
    </>
  )
}

export default Chat
