// import axios from "axios";
// import React, { useEffect, useState } from "react";
// import { Col, Divider, Row, Table } from "antd";
// import * as XLSX from "xlsx";
// import ReactPaginate from "react-paginate";
// import DatePicker from "react-multi-date-picker";
// import { DateObject } from "react-multi-date-picker";
// import { useLocation } from 'react-router-dom';
// import admindata from "../../components/admindata";


// const $ = require("jquery");
// $.Datatable = require("datatables.net");

// const DepositReport = () => {

//   const location = useLocation();
//   const userData = location.state?.userData;

//   const [CompanyName, setCompanyName] = useState("");
//   const [CompanyMobile, setCompanyMobile] = useState("");
//   const [CompanyAddress, setCompanyAddress] = useState("");

// console.log(userData)
//   const [user, setUser] = useState();
  
//   const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
//   const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
//   const nodeMode = process.env.NODE_ENV;
//   if (nodeMode === "development") {
//     var baseUrl = beckendLocalApiUrl;
//   } else {
//     baseUrl = beckendLiveApiUrl;
//   }
  
//   useEffect(() => {
//     const data = axios.get(baseUrl + "settings/data", {}).then((res) => {
//       setCompanyName(res.data.CompanyName);
//       setCompanyMobile(res.data.CompanyMobile);
//       setCompanyAddress(res.data.CompanyAddress);
//     });
//   }, []);
  
//   //use for pagination..
//   let [limit,setLimit] = useState(10);

//   const setpageLimit = (event)=>{
//     let key = event.target.value
//     setLimit(key);
// }
//   const [pageNumber, setPageNumber] = useState(0);
//   const [numberOfPages, setNumberOfPages] = useState(0);
//   const [values, setValues] = useState([
//     new DateObject().subtract(4, "days"),
//     new DateObject().add(4, "days"),
//   ]);

//   //user for searching..
//   // const [searchList, setSearchList] = useState(0);
//   // const [searchType, setSearchType] = useState(0);

 
//   //react paginate..
//   const handlePageClick = async (data) => {
//     let currentPage = data.selected + 1;
//     setPageNumber(currentPage);
//     // scroll to the top
//     //window.scrollTo(0, 0)
//   };

//   const profle = () => {
//     const access_token = localStorage.getItem("token");
//     const headers = {
//       Authorization: `Bearer ${access_token}`,
//     };
//     axios
//       .get(
//         baseUrl +
//           `txn/depositreports/all?page=${pageNumber}&_limit=${limit}&FROM_DATE=${values[0]}&TO_DATE=${values[1]}`,
//         { headers }
//       )
//       .then((res) => {
//         setUser(res.data.datefind);
//         setNumberOfPages(res.data.totalPages);
//         //$('table').dataTable();
//       });
//   };

//   const dateFormat = (e) => {
//     const date = new Date(e);
//     const newDate = date.toLocaleString("default", {
//       year: "numeric",
//       month: "numeric",
//       day: "numeric",
//     });
//     return newDate;
//   };

//   useEffect(() => {
//     profle();
//   }, [pageNumber, limit, values]);

//   if (user === undefined) {
//     return null;
//   }


//   const Print = () => {
//     //console.log('print');
//     let printContents = document.getElementById(`printablediv`).innerHTML;
//     let originalContents = document.body;
//     document.body.innerHTML = printContents;
//     window.print();
//     document.body= originalContents;
//   };

//   const createDownloadData = () => {
//     handleExport();
//   };
//   const handleExport = () => {
//     let table1 = [
//       {
//         A: "Id",
//         B: "UserName",
//         C: "PhoneNumber",
//         D: "Deposit Amount",
//         E: "Deposit Status",
//       },
//     ];

//     userData?.data?.User_id?.forEach((row) => {
//       const userDetails = row;
//       console.log("exldata", userDetails);
//       table1.push({
//         A: userDetails._id,
//         B: userDetails.User_id ? userDetails.User_id.Name : "",
//         C: userDetails.User_id ? userDetails.User_id.Phone : "",
//         D: userDetails.amount ? userDetails.amount : "",
//         E: userDetails.status ? userDetails.status : "",
//       });
//     });

//     //table1 = [{A:"User Details"}].concat(table1);
//     //const finalData = [...table1];
//     //console.log(finalData);
//     /* convert state to workbook */
//     const ws = XLSX.utils.json_to_sheet(table1, {
//       skipHeader: true,
//     });
//     const wb = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
//     /* generate XLSX file and send to client */
//     XLSX.writeFile(wb, "DepositReport.xlsx");
//   };
//   return (
//     <div>
//       {/* <h4 className='font-weight-bold my-3'>ALL CHALLANGES</h4> */}
//       <div className="row ">
//         <div className="col-12 grid-margin">
//           <div className="card">
//             <div className="card-body text-light" style={{backgroundColor:"rgba(0, 27, 11, 0.734)"}}>
//               <h4 className="card-title">Deposit Reports</h4>
//               <button
//                 onClick={() => {
//                   createDownloadData();
//                 }}
//                 className="btn btn-primary"
//               >
//                 Export Data
//               </button>
//               <button type="button" className="btn btn-success" onClick={Print}>
//                 Print
//               </button>
//               <div>
//               <select  className='form-control col-sm-1 m-1 bg-dark text-light' id='pagelimit' name='pagelimit' onChange={setpageLimit}>
//                                 <option value="10">Set limit</option>
//                                 <option value="20">20</option>
//                                 <option value="50">50</option>
//                                 <option value="100">100</option>
//                                 <option value="500">500</option>
//                             </select>
//     </div>
//               <DatePicker value={values} onChange={setValues} range />
//               <div style={{ padding: 20 }}></div>
//               <div className="table-responsive" >
//                 <div>
//                   {/* {user &&
//                     userData.data?.map((data, key) =>{  */}
//                       {/* var id          = data._id.toString(), ctr = 0;
//                       var timestamp   = id.slice(ctr, (ctr+=8));
//                       var machineID   = id.slice(ctr, (ctr+=6));
//                       var processID   = id.slice(ctr, (ctr+=4));
//                       var counter     = id.slice(ctr, (ctr+=6));
//                       //console.log("id:", id);
//                       //console.log("timestamp:", parseInt(timestamp, 16));
//                       //console.log("machineID:", parseInt(machineID, 16));
//                       //console.log("processID:", parseInt(processID, 16));
//                       var counterid =parseInt(timestamp, 16);  */}
//                       {/* return( */}
                      
//                       <div id="printablediv">
//                         <Row>
//                           <Col>
//                             <Divider>Invoice</Divider>
//                           </Col>
//                         </Row>

//                         <Row gutter={24} style={{ marginTop: 32 }}>
//                           <Col span={8}>
//                             <h3>{CompanyName}</h3>
//                             <div>{CompanyAddress}</div>
//                             <div>GSTIN : 08AAPCM8651H1ZW</div>
//                             <div>MOB: {CompanyMobile}</div>
//                           </Col>


//                           <Col span={8} offset={8}>
//                             <table>
//                               <tr>
//                                 <th>
//                                   Invoice Date :{" "}
//                                   {dateFormat(userData.data?.createdAt).split(",")[0]}{" "}
//                                 </th>
                                
//                                 <td></td>
//                               </tr>
//                             </table>
//                           </Col>
//                         </Row>

//                         <Row style={{ marginTop: 48 }}>
//                           <div>
//                             User Name : {userData.data?.User_id?.Name}
//                             <h6>
//                               {" "}
//                               {(userData.data?.User_id && userData.data?.User_id?.holder_name) ? userData.data?.User_id?.holder_name : userData.data?.User_id?.Name}
//                               </h6>
                             
//                               {admindata?.user?.user_type == 'Admin' || admindata?.user?.updateLegalData === true ?  <h6>
//                               {userData.data?.User_id ? userData.data?.User_id?.Phone : ""}
//                               </h6>
//                             : <h6>{`${'********'+userData.data?.User_id?.Phone.toString().slice(-2)}`}</h6>}
//                           </div>
//                         </Row>

//                         <Row style={{ marginTop: 48 }}>
//                           <Table
//                             dataSource={[
//                               {
//                                 id: 1,
//                                 name: "Deposit",
//                                 price: `${userData.data?.amount ? "Rs. "+userData.data?.amount : ""}`,
//                                 ID: `MSG-${userData.data?.counterid}`,
//                                 TxnID: `${userData.data?.order_token}`,
//                                 paymentType: `UPI`,
//                                 paymentGatway: `${userData.data?.payment_gatway}`,
//                               },
//                             ]}
//                             pagination={false} >
//                             <Table.Column title="Description" dataIndex="name"/>
//                             <Table.Column title="Amount" dataIndex="price" />
//                             <Table.Column title="OrderID" dataIndex="ID" />
//                             <Table.Column title="TxnID" dataIndex="TxnID" />
//                             <Table.Column title="PaymentType" dataIndex="paymentType" />
//                             <Table.Column title="PaymentGatway" dataIndex="paymentGatway" />
//                           </Table>
//                         </Row>

//                         <Row style={{ marginTop: 48 }}>
//                           <Col span={8} offset={16}>
//                             <table>
//                               <tr>
//                                 <th> Total :</th>
//                                 <td>{userData.data?.amount ? "Rs. "+userData.data?.amount : ""}</td>
//                               </tr>
//                             </table>
//                           </Col>
//                         </Row>

//                         <Row style={{ marginTop: 48, textAlign: "center" }}>
//                           <Col span={8}>
//                             <div>Account No: 071263300004243</div>
//                             <div>IFSC Code: YESB0000712</div>
//                             <div>YES BANK</div>
//                           </Col>
//                         </Row>

//                         <Row style={{ marginTop: 48, textAlign: "center" }}>
//                           ----*THIS IS A COMPUTER GENERATED INVOICE NO SIGNATURE
//                           REQUIRED*----
//                         </Row>
//                       </div>
//                     {/* )})} */}
//                 </div>
//               </div>

//               <div className="mt-4">
//                 <ReactPaginate
//                   previousLabel={"Previous"}
//                   nextLabel={"Next"}
//                   breakLabel={"..."}
//                   pageCount={numberOfPages}
//                   marginPagesDisplayed={2}
//                   pageRangeDisplayed={3}
//                   onPageChange={handlePageClick}
//                   containerClassName={"pagination justify-content-center"}
//                   pageClassName={"page-item"}
//                   pageLinkClassName={"page-link"}
//                   previousClassName={"page-item"}
//                   previousLinkClassName={"page-link"}
//                   nextClassName={"page-item"}
//                   nextLinkClassName={"page-link"}
//                   breakClassName={"page-item"}
//                   breakLinkClassName={"page-link"}
//                   activeClassName={"active"}
//                 />
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default DepositReport;



import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { useState } from 'react'
import moment from 'moment'
import { useLocation } from 'react-router-dom';
import './invoice.css'

const DepositReport = ({ pageprint , printRef, invoice_data }) => {
  const location = useLocation();
  const userData = location.state?.userData;
  const [user, setUserData] = useState(userData)
console.log(user)
  // setUserData(userData)
  const Print = () =>{     
    //console.log('print');  
    let printContents = document.getElementById('printablediv').innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
   document.body.innerHTML = originalContents; 
  }
  var id = user?.data?._id.toString(),
  ctr = 0
  var timestamp = id.slice(ctr, (ctr += 8))
  var counterid = parseInt(timestamp, 16)

  return (
    <>
    <div>
      <div style={{display: "flex",justifyContent: "end"}}>
        <button onClick={Print} class="btn btn-primary mb-2">PRINT</button>
      </div>
          <div className='invoice invoicepage'  id='printablediv'>
            <table className='table_one boder_bottom w_100'>
              <tr className='boder_bottom'>
                <th colspan='3'>
                  <div className='top_table table-1-head'>
                    <div>RECEIPT VOUCHER</div>
                    <div className='font_sm'>Original</div>
                  </div>
                </th>
              </tr>
              <tr>
                <td rowSpan='5' className='width_50'>
                  <div className='table_1'>
                    <h1 className='company_name'>LAVYANSHU WEBTECH</h1>
                    <div className='detail'>
                      <address>
                       <div>OFFICE NO.-433,UNNATI TOWER, VIDHYADHAR NAGAR</div>
                       <div> JAIPUR-302039, RAJASTHAN</div>
                      </address>
                      <div>
                        <strong>GSTIN : </strong>
                        <span>08AAFCL5058F1ZG</span>
                      </div>
                      <div>
                        <strong>MOB:</strong>
                        <span>+91- 1169311448</span> |
                        <span> helpprobattle@gmail.com</span>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>

              <tr className='boder_bottom'>
                <td className='text_center width_25 '>
                  <h4 className='date'>DATE :</h4>
                  <h4 className='date'>Voucher : </h4>
                </td>
                <td className='text_center width_25 '>
                  <h4 className='date'>
                    {moment(user?.data?.createdAt).format('DD-MM-YYYY')}
                  
                  </h4>
                  {/* <h4 className='date'>MSG-{invoice_data?.counterid}</h4> */}
                  <h4 className='date'>MSG-{counterid}</h4>
                </td>
              </tr>
              <tr className='boder_bottom '>
                <td colspan='2' className=''>
                  <p style={{ opacity: 0 }}>.</p>
                </td>
              </tr>
              <tr className='boder_bottom '>
                <td colspan='2' className='text-center '>
                  {' '}
                  <strong> Mode Of Payment / Transaction id </strong>
                </td>
              </tr>
              <tr>
                <td colspan='2' className=''>
                  <div className='detail paytment'>
                    <div>
                      <span>Payment Method :</span>
                      <span>{user?.data?.payment_gatway}</span>
                    </div>
                    <div>
                      <span>Orer Id :</span>
                      <span>{user?.data?.order_id}</span>
                    </div>
                    <div>
                      <span>Txn id :</span>
                      <span>{user?.data?.order_token ?? user?.data?.order_id}</span>
                    </div>
                  </div>
                </td>
              </tr>
            </table>
            <div>
              <div className='table_bottom onediv pd_left'>
                <div>
                  <span className='ms-1'>TO </span>
                  <span>
                    {/* {invoice_data.Name} ({invoice_data.Phone}) */}
                  {user?.data?.User_id?.Name} ({user?.data?.User_id?.Phone})
                  </span>
                </div>
                Email : <a href=''>{user?.data?.User_id?.Email}</a>
              </div>

              <table className=' w_100 '>
                <tr className='text-center '>
                  <th className='width_5'>S.NO.</th>
                  <th className='width_45 '>DESCRIPTION</th>
                  <th className='width-30 '>HSN CODE</th>
                  <th className='width_20 '>AMOUNT</th>
                </tr>
                <tr className='table_2_tr detail '>
                  <td className='text-center width_5 '>1</td>
                  <td className='width_45 pd_left '>
                    Wallet Balance
                  </td>
                  <td className='text-center width_30 '>
                    {/* {invoice_data.counterid} */}
                    998439
                  </td>

                  <td className='text-center width_20 '>
                    {/* {invoice_data.cardAmount} */}
                    {user?.data?.amount}
                  </td>
                </tr>
                <tr className='text-center  boder_bottom'>
                  <td colspan='2'></td>
                  <td className=''>
                    {' '}
                    <strong>TOTAL</strong>{' '}
                  </td>
                  <td className=''>
                    <strong>{user?.data?.amount}</strong>
                   
                  </td>
                </tr>
              </table>

              <div className='bottom_table'>
                <div className='width_55 la'>
                  <table className='same_height last'>
                    <tr className='boder_bottom'>
                      <td>
                        <p className='op'> 1</p>
                      </td>
                    </tr>
                    <tr className='boder_bottom'>
                      <td colspan='2' className='pd_left boder-top'>
                        <div className='boder-top'>
                          <p>
                            <strong>Pay To</strong>
                          </p>
                          <div className='detail2 '>
                            <p> LAVYANSHU WEBTECH PRIVATE LIMITED </p>
                            <p>
                              <span>Account No :-</span>
                              <span>407878848280</span>
                            </p>
                            <p>
                              <span>ISFC Code : </span>{' '}
                              <span>RATN0000438</span>
                              <div>RBL BANK</div>
                            </p>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className='detail2 pd_left'>Receiver signature</td>
                    </tr>
                  </table>
                </div>

                <div className='width_45 la2'>
                  <table className='same_height'>
                    <tr className='boder_bottom'>
                      <td className='width_25 text_center hei_30'>
                        {' '}
                        <div style={{ fontWeight: 500 }}>Round Off</div>
                        <div>
                          <strong>GRAND TOTAL</strong>
                        </div>
                      </td>

                      <td className='width_20 text_center hei_30 '>
                        <div style={{ fontWeight: 500 }}>0</div>{' '}
                        <div>
                          <strong>{user?.data?.amount}</strong>
                       
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td className='text-center width_45 detail2' colspan='2'>
                        FOR LAVYANSHU WEBTECH PRIVATE LIMITED
                        <div> AUTHORRISED SIGNATORY </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'end',
                paddingTop: '5px'
              }}
            >
              This is a computer generated invoice no signature required
              
            </div>
          </div>
    </div>
    </>
  )
}

export default DepositReport