// import React, { useState } from 'react'
import { CiMenuBurger, CiImageOn } from 'react-icons/ci'
import { CgProfile } from 'react-icons/cg'
import { RiCloseCircleLine } from 'react-icons/ri'
import React, { useEffect, useState, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import socket from '../../../socket/socket'
import { Modal, Button, Alert } from 'react-bootstrap'
import axios from 'axios'
import { GiClick } from 'react-icons/gi'
import './Chat.css'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import moment from 'moment'

import { FaRegUserCircle } from 'react-icons/fa'
import { BsList } from 'react-icons/bs'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter
} from 'mdb-react-ui-kit'
import Dropdown from 'react-bootstrap/Dropdown'

import { Link } from 'react-router-dom'
const NewChat = () => {
  const [isActive, setIsActive] = useState(false) // State to track the class

  const toggleClass = () => {
    setIsActive(!isActive)
  toggleChatList() // Toggle the state value
  }

  const [topRightModal, setTopRightModal] = useState(false)

  const [messages, setMessages] = useState([])
  const [inputValue, setInputValue] = useState('')
  const [isFileSelected, setIsFileSelected] = useState(false)
  const [allMsg, setAllMsg] = useState([])
  const [data, setAllData] = useState([])
  const [selectedFile, setSelectedFile] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalShow, setModalShow] = React.useState(false)
  const [showChatList, setShowChatList] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [isloading, setIsLoading] = useState(false)
  const history = useHistory()

  const [List, setList] = useState([])
  const [ConversationId, setConversationId] = useState([])
  const [userId, setUserId] = useState([])
  const [ticket, setTicket] = useState({})
  const [RemarkMsg, setRemarkMsg] = useState({})
  const [isNew, isnewSet] = useState(true)

  const Token = localStorage.getItem('token')

  
  const handleSearch = e => {
    setSearchQuery(e.target.value)
  }

  // Filter data based on search query
  const filteredData = List?.filter(item => {
    const itemData = item?.ticket_id.toLowerCase() // Change 'name' to the relevant property in your API data
    return itemData && itemData?.includes(searchQuery.toLowerCase())
  })

  const TicketList = () => {
    // Conversation()
    // setIsLoading(true)
    var myHeaders = new Headers()
    myHeaders.append('Authorization', Token)

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    }
    fetch(
      'https://api.probattle.in/chat/ticket-list/admin?page=1&limit=1000',
      requestOptions
    )
      .then(response => response.json())
      .then(result => {
        // setIsLoading(false)
        setList(result.results)
        if (isNew) {
          Conversation(result.results[0])
        }
        isnewSet(false)
      })
      .catch(error => {
        // setIsLoading(false)
        console.log(error)
      })
  }

  const Conversation = ticketobj => {
    setIsLoading(true)
    setUserId(ticketobj.user_id)
    setTicket(ticketobj)
    setConversationId(ticketobj._id)
    var myHeaders = new Headers()
    myHeaders.append('Authorization', Token)

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    }

    // const conversationId =  localStorage.getItem("id")|| ticketobj._id ; // Use ticketobj._id if available, otherwise fallback to List[0]._id

    fetch(
      `https://api.probattle.in/chat/conversation/${ticketobj._id}?page=1&limit=50`,
      requestOptions
    )
      .then(response => response.json())
      .then(result => {
        setIsLoading(false)
        if (result) {
          setShowChatList(!showChatList)
        }
        setAllMsg(result.results)
        setAllData(result)
      })
      .catch(error => {
        setIsLoading(false)
        console.log(error)
      })
  }

  // localStorage.setItem("id", List[0]?._id)//

  const openModal = () => {
    setModalShow(true)
  }

  const access_token = localStorage.getItem('token')
  const [user, setUser] = useState({})
  // useEffect(()=>{
  //   socket.emit("joinUser", "dekho kitni bar call hua")

  // },[])
  const getUser = () => {
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    axios
      .get('https://api.probattle.in/me', { headers })
      .then(res => {
        socket.emit('joinUser', res.data)
        console.log(socket)
        setUser(res.data)
        // toast("Login Success")
      })
      // console.log("first")
      .catch(e => {
        toast('pls login')
        if (e.response.status == 401) {
          localStorage.removeItem('token')
          // history.pushState("/login")
        }
      })
  }

  useEffect(() => {
    getUser()
  }, [])

  const generate_url = picture => {
    const formData = new FormData()
    formData.append('media', picture)

    fetch('https://api.24x7mail.com/url', {
      method: 'POST',
      body: formData
    })
      .then(response => response.json())
      .then(json => {
        setSelectedFile(json.data[0])
      })
  }

  const closeModal = () => {
    setModalShow(false)
    setSelectedFile(null)
    setIsFileSelected(false)
  }

  const handleInputChange = e => {
    setInputValue(e.target.value)
  }

  const selectFile = () => {
    document.getElementById('myFileInput').click()
  }

  const handleFileSelect = event => {
    const file = event.target.files[0]
    generate_url(file)
    setIsFileSelected(true)
    openModal()
  }

  // socket.emit("joinUser", `${socket.id} is joined`);
  const ImageUrl = 'https://api.24x7mail.com/uploads/'

  const handleSendMessage = () => {
    inputRef.current.value = ''
    if (inputValue !== '' || isFileSelected) {
      const newMessage = {
        text: inputValue,
        recipients: userId,
        media: selectedFile && ImageUrl + selectedFile,
        chat_id: ConversationId,
        agent_id: user._id
      }
      socket.emit('create-msg', newMessage)
      setMessages([...messages, newMessage])
      setAllMsg([...allMsg, newMessage])
      setInputValue('')
      setSelectedFile(null)
      setIsFileSelected(false)
      closeModal()
    }
  }


  useEffect(() => {

   

    socket.on('recive-msg', msgs => {
      if (ticket.ticket_id === msgs.chat_id) {
        setAllMsg([...allMsg, msgs])
      }
    })

    socket.on('conversation', data => {
      setAllMsg(data.results)
    })
    socket.on('ended', data => {
      console.log(data, 'ended')
      Conversation(data)
    })

    socket.on('disconnect', data => {
      socket.emit('joinUser', user)
    })
  }, [messages, socket])

  const toggleChatList = () => {
    TicketList()
    setShowChatList(!showChatList)
  }

  const toggleShow = () => {
    setTopRightModal(!topRightModal)
    Conversation(ticket)
  }

  const EndChat = () => {
    // window.location.reload();
    TicketList()
    toggleShow()
    socket.emit('endChat', { ticket_id: ticket.ticket_id, remark: RemarkMsg })
  }
  function imageViewer () {
    let imgs = document.getElementsByClassName('img'),
      out = document.getElementsByClassName('img-out')[0]

    for (let i = 0; i < imgs.length; i++) {
      if (!imgs[i].classList.contains('el')) {
        imgs[i].classList.add('el')
        imgs[i].addEventListener('click', lightImage)
        function lightImage () {
          let container = document.getElementsByClassName('img-panel')[i]
          container.classList.toggle('img-panel__selct')
        }

        imgs[i].addEventListener('click', openImage)
        function openImage () {
          let imgElement = document.createElement('img'),
            imgWrapper = document.createElement('div'),
            imgClose = document.createElement('div'),
            container = document.getElementsByClassName('img-panel')[i]
          container.classList.add('img-panel__selct')
          imgElement.setAttribute('class', 'image__selected')
          imgElement.src = imgs[i].src
          imgWrapper.setAttribute('class', 'img-wrapper')
          imgClose.setAttribute('class', 'img-close')
          imgWrapper.appendChild(imgElement)
          imgWrapper.appendChild(imgClose)

          setTimeout(function () {
            imgWrapper.classList.add('img-wrapper__initial')
            imgElement.classList.add('img-selected-initial')
          }, 50)

          out.appendChild(imgWrapper)
          imgClose.addEventListener('click', function () {
            container.classList.remove('img-panel__selct')
            out.removeChild(imgWrapper)
          })
        }
      }
    }
  }

  useEffect(() => {
    TicketList()
    // Conversation()
  }, [])

  function getStatusColor (status) {
    switch (status) {
      case 0:
        return 'New'
      case 1:
        return 'Progress'
      case 3:
        return 'Closed'
      default:
        return ''
    }
  }

  function getStatusColor1 (status) {
    switch (status) {
      case 0:
        return 'white'
      case 1:
        return 'green'
      case 3:
        return 'red'
      default:
        return ''
    }
  }

  // Enter Button
  const inputRef = useRef(null)

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleSendMessage()
    }
  }

  // Support Type
  const openPathInNewTab = path => {
    window.open(path, '_blank')
  }

  const UserType = () => {
    if (ticket.supportType === 'Profile') {
      const path = `./view_user/${ticket.user_id}`
      openPathInNewTab(path)
    } else if (ticket.supportType === 'Game') {
      if (ticket.issueid) {
        const path = `/view/${ticket.issueid}`
        openPathInNewTab(path)
      } else {
        const path = `./view_user/${ticket.user_id}`
        openPathInNewTab(path)
      }
    } else if (ticket.supportType === 'Deposit') {
      const path = `/transaction/transaction_history/${ticket.user_id}`
      openPathInNewTab(path)
    } else if (ticket.supportType === 'Referral') {
      const path = `./view_user/${ticket.user_id}`
      openPathInNewTab(path)
    } else if (
      ticket.supportType === 'Penalty' ||
      ticket.supportType === 'Bonus'
    ) {
      const path = `/transaction/penaltybonus`
      openPathInNewTab(path)
    } else if (ticket.supportType === 'Withdraw') {
      const path = `/transaction/withdraw/${ticket.user_id}`
      openPathInNewTab(path)
    } else if (ticket.supportType === 'Others') {
      const path = `./view_user/${ticket.user_id}`
      openPathInNewTab(path)
    }
  }

  const containerRef = useRef(null)

  // Scroll to the bottom on initial render and whenever new messages are added
  useEffect(() => {
    scrollToBottom()
  }, [allMsg]) // Replace `messages` with your messages data source

  const scrollToBottom = () => {
    containerRef.current.scrollTop = containerRef.current.scrollHeight
  }


  return (
    <div>
      <div className='chat_main_section'>
        <div className='card'>
          <div className='card-body  table_bg'>
            {/* <h4 className='dashboard_heading my-3'>Chat</h4> */}

            <section className='chat_main_section_main'>
              <div className='chat_main_section'>
                <div
                  className={`chat_side_list_div custom_scroll ${
                    isActive ? 'small_box' : ''
                  }`}
                >
                    <div className='chat_box_search side_chat_search'>
                      <input   type='search'
                    placeholder="What're you searching for?"
                    aria-describedby='button-addon4'
                    // class='form-control bg-none border-0 rounded-pill mr-1 SearchBox'
                    value={searchQuery}
                    onChange={handleSearch} />
                    </div>
                  <div className='' onClick={toggleClass}>
                    {filteredData?.map(text => {
                    return (
                      <>
                      {text.status !== undefined && (
                    <div className='user_single_chat_list'
                    onClick={() => Conversation(text)}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      cursor: 'pointer',
                      alignItems: 'center'
                    }}
                    >
                      <div>
                      <h3>{text.ticket_id}</h3>
                        <h4>Profile</h4>
                        <h6  style={{
                                  fontSize: '12px',
                                  fontStyle: 'italic',
                                  color: getStatusColor1(text.status)
                                }}>
                                 {getStatusColor(text.status)}
                                  </h6>
                      </div>
                    {text.unseen === 0 ? " " : <h4 className='msg_number'>{text.unseen}</h4>}  
                    </div>
                    )}
                    </>
                    )
                  })}
                  </div>
                </div>
                <div className={`chat_msg_side ${isActive ? 'big_box' : ''}`}>
                  <div className='chat_msg_side_main'>
                    <div className='chat_container_top_bar'>
                      <div className='d-flex'>
                        <button onClick={toggleClass}>
                          <CiMenuBurger className='icon' />
                        </button>
                        <h3> Support Id : {ticket.ticket_id}</h3>
                      </div>
                        <h3> Phone : {data?.chat?.user_id?.Phone}</h3>
                      <Link className='profile_icon'>
                        <RiCloseCircleLine className={ticket.status === 3 ? 'd-none' : ''} onClick={toggleShow} title='End Chat'/>
                        <CgProfile className='ml-3' onClick={UserType} title='Profile'/>
                      </Link>
                    </div>
                    <div className='chat_msg_main_div'
                     ref={containerRef}>
                      {allMsg &&
                  allMsg?.map((message, index) => (
                      <div
                      // key={index}
                      // className={`message ${
                      //   message?.recipients === userId
                      //     ? 'user-message'
                      //     : 'admin-message'
                      // }`}
                      // style={{
                      //   flexDirection:
                      //     message?.recipients === userId ? 'row-reverse' : 'row'
                      // }}
                      >
                        
                      <div 
                       className={`message ${
                        message?.recipients === userId
                          ? 'chat_msg left_side_msg'
                          : 'chat_msg right_side_msg'
                        }`}
                      >

<div>
                        {message?.media?.length > 0 && (
                          //  <a href={message.media} target="_blank">
                          <Zoom>
                            <img
                              alt=''
                              src={message.media}
                              className='img-fluid'
                              style={{ height: "300px", width: "200px" }}
                            />
                          </Zoom>

                         
                        )}
                      </div>

                        <p  style={{
                          display: message?.text?.length > 0 ? 'inline' : ''
                        }}>
                        {message?.text?.replace(/<br>/g, '')}
                        </p>
                        <span className='msg_time_date'>
                        {moment(message?.startTime)
                            .utcOffset('+05:30')
                            .format('llll')}
                        </span>
                      </div>


                      {/* <div className='chat_msg left_side_msg'>
                      
                         {message?.media?.length > 0 && (
                          //  <a href={message.media} target="_blank">
                          <Zoom>
                            <img
                              alt=''
                              src={message.media}
                              className='img-fluid'
                            />
                          </Zoom>

                          // <img
                          //   src={message.media}
                          //   className="img-fluid"
                          //   style={{ height: "200px", width: "150px" }}
                          // />
                          // </a>
                        )}
                        <span className='msg_time_date'>
                          Fri, Aug 4, 2023 5:12 PM
                        </span>
                      </div> */}
                      </div>
                      ))}
                    </div>

                    <div className='msg_container_footer'>
                      <div className='msg_container_footer_inner'>
                      <input
                  type='file'
                  id='myFileInput'
                  onChange={handleFileSelect}
                  style={{ display: 'none' }}
                />
  {ticket.status === 3 ? (
                  <>
                    <div
                      className='text-center'
                      style={{ width: '100%', background: '#fff' }}
                    >
                      <div className='text-danger '>
                        {' '}
                        Chat was ended. <br />{' '}
                        <div className='text-success'>
                          Remark: {ticket?.remark}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                        <div className='choose_image_btnn'>
                          <CiImageOn className='icon' onClick={selectFile} />
                        </div>
                        <div className='msg_type_input_box'>
                          <input
                            type='text'
                            value={inputValue}
                            onChange={handleInputChange}
                            onKeyPress={handleKeyPress}
                            ref={inputRef}
                            placeholder={
                              ticket.status === 0
                                ? 'Send message to start support'
                                : 'Type your message...'
                            }
                          />
                          <button onClick={handleSendMessage}>Send</button>
                        </div>
                        </>
                )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Modal
              show={modalShow}
              onHide={() => setModalShow(false)}
              aria-labelledby='contained-modal-title-vcenter'
              centered
            >
              <Modal.Body className='p-1 m-0'>
                {selectedFile && (
                  <img
                    src={ImageUrl + selectedFile}
                    className='img-fluid img'
                    style={{ height: '350px', width: '100%' }}
                    alt='Selected file preview'
                  />
                )}
                <div>
                  {moment(allMsg?.startTime).utcOffset('+05:30').format('llll')}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <input
                  type='text'
                  value={inputValue}
                  onChange={handleInputChange}
                  placeholder='Type your message...'
                  className='chat_input_modal'
                />
                <button className='modal_button' onClick={handleSendMessage}>
                  Send
                </button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
      <MDBModal
        animationDirection='right'
        show={topRightModal}
        tabIndex='-1'
        setShow={setTopRightModal}
      >
        <MDBModalDialog position='top-right' side>
          <MDBModalContent>
            <MDBModalHeader className='bg-info text-white'>
              <MDBModalTitle>End Chat</MDBModalTitle>
              <MDBBtn
                color='none'
                className='btn-close btn-close-white'
                onClick={toggleShow}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <div className='row'>
                <div className='col-3 text-center'>Remark</div>

                <div className='col-9'>
                  <textarea
                    className='form-control '
                    placeholder='Thanks For Connect with us'
                    onChange={e => setRemarkMsg(e.target.value)}
                  ></textarea>
                </div>
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color='info' onClick={EndChat}>
                Submit
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </div>
    
  )
}

export default NewChat
